import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

if (environment.isAptyEnabled && `${environment.isAptyEnabled}` === 'true') {
  loadAptyDap();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

function loadAptyDap(): void {
  const contentURL = 'https://client.app.apty.io';
  const element = window.document.createElement('script');
  const tenantKey = 'hnZeCo7N';
  const timeStamp = new Date().getTime();
  element.setAttribute('src', contentURL + `/tenant-${tenantKey}/injected-hook.js?timestamp=${timeStamp}&token=`);
  window.document.head.appendChild(element);
}