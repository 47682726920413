import { createReducer, on} from '@ngrx/store'
import { ProjectDetailsModel } from '../../widgets/staar/models/project-details'
import { setProject } from './projects.actions'


export type ReducerState = {
  projects: Record<string, ProjectDetailsModel>
  selectedProjectNumber: string
}

const initialState: ReducerState = {
  projects: {},
  selectedProjectNumber: null
}

const _projectsReducer = createReducer<ReducerState>(
  initialState,
  on(setProject, (state, { project}) => {
    const selectedProjectNumber = project.PROJECT_NUMBER
    const projects = { ...state.projects };
    projects[selectedProjectNumber] = project
    return {...state, projects, selectedProjectNumber }
  })
)

export const projectsReducer = (state, action) => _projectsReducer(state, action)