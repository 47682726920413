export class ApiPayload {
    
    private NGE_API_EMPLOYEE_ID: string = ''
    private NGE_API_USER_NAME: string = ''
    NGE_API_CHANNEL: string = "BROWSER";

    tokenPayLoad: any = {
        employee_id: this.NGE_API_EMPLOYEE_ID,
        application: "CONCOURSE",
        userName: this.NGE_API_USER_NAME,
        channel: this.NGE_API_CHANNEL
    }
    
    ediPayload: any = { 
        employee_id: this.NGE_API_EMPLOYEE_ID,
        application: "CONCOURSE",
        userName: this.NGE_API_USER_NAME,
        channel: this.NGE_API_CHANNEL,
        token: "",
        assignCustomerRFQ: []
    }

    public setTokenPayload(empId: string, userName: string) {
        this.tokenPayLoad.employee_id = empId;
        this.tokenPayLoad.userName = userName;
    }
}