import { Injectable, OnInit, Renderer2, RendererFactory2 } from '@angular/core';
import { Subject } from 'rxjs';
import { ColorScheme } from '../models/color-schemes';


@Injectable({
  providedIn: 'root'
})
export class ColorSchemeService implements OnInit {
  private renderer: Renderer2;
  private colorScheme: string;
  // Define prefix for clearer and more readable class names in scss files
  private colorSchemePrefix = 'color-scheme-';
  themeModechange: Subject<string> = new Subject<string>();

  constructor(rendererFactory: RendererFactory2) {
    // Create new renderer from renderFactory, to make it possible to use renderer2 in a service
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  ngOnInit(): void {
    this.getColorScheme();
  }

  load(): void {
    this.getColorScheme();
  }

  update(scheme: string): void {
    this.setColorScheme(scheme);
    // Remove the old color-scheme class
    this.renderer.removeClass(document.body, this.colorSchemePrefix + (this.colorScheme === 'dark' ? 'light' : 'dark'));
    // Add the new / current color-scheme class
    this.renderer.addClass(document.body, this.colorSchemePrefix + scheme);
  }

  currentActive(): string {
    return this.colorScheme;
  }

  private detectPrefersColorScheme(): void {
    // Detect if prefers-color-scheme is supported
    if (window.matchMedia('(prefers-color-scheme)').media !== 'not all') {
      // Set colorScheme to Dark if prefers-color-scheme is dark. Otherwise, set it to Light.
      this.colorScheme = window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
    } else {
      // If the browser does not support prefers-color-scheme, set the default to dark.
      this.colorScheme = 'dark';
    }
  }

  private setColorScheme(scheme: string): void {    
    let newScheme = scheme;
    if (scheme === ColorScheme.default) {
      this.detectPrefersColorScheme();
      newScheme = this.colorScheme;
    }
    this.colorScheme = newScheme;
    // Save prefers-color-scheme to localStorage
    localStorage.setItem('prefers-color', newScheme);
    this.themeModechange.next(this.colorScheme);
  }

  private getColorScheme(): void {
    const localStorageColorScheme = localStorage.getItem('prefers-color');
    // Check if any prefers-color-scheme is stored in localStorage
    if (localStorageColorScheme) {
      // Save prefers-color-scheme from localStorage
      this.colorScheme = localStorageColorScheme;
    } else {
      // If no prefers-color-scheme is stored in localStorage, try to detect OS default prefers-color-scheme
      this.detectPrefersColorScheme();
    }
  }
}
