import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { UserCredentialsGuard } from './shared/services/user-credentials.guard';

const routes: Routes = [
  {
    path: '',
    canActivate: [MsalGuard],
    loadChildren: () => import('./pages/login-form/login-form.module').then(m => m.LoginFormModule)
  },
  {
    path: 'dashboard',
    canActivate: [MsalGuard, UserCredentialsGuard],
    loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: 'user-preferences',
    canActivate: [MsalGuard, UserCredentialsGuard],
    loadChildren: () => import('./pages/user-preferences/user-preferences.module').then(m => m.UserPreferencesModule)
  },
  {
    path: 'aar',
    canActivate: [MsalGuard, UserCredentialsGuard],
    loadChildren: () => import('./widgets/aar/aar.module').then(m => m.AarModule)
  },
  {
    path: 'staar',
    canActivate: [MsalGuard, UserCredentialsGuard],
    loadChildren: () => import('./widgets/staar/staar.module').then( m => m.StaarPageModule)
  },
  {
    path: 'imops',
    canActivate: [MsalGuard, UserCredentialsGuard],
    loadChildren: () => import('./widgets/imops/imops.module').then( m => m.ImopsModule)
  },
  {
    path: 'management',
    canActivate: [MsalGuard, UserCredentialsGuard],
    loadChildren: () => import('./pages/management/management.module').then( m => m.ManagementPageModule)
  },
  { path: '**', redirectTo: '', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
