import { NgModule } from '@angular/core';
import { applicationStoreForRoot } from './application-store';
import { EffectsModule } from '@ngrx/effects'
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../../environments/environment'

import { WorkCardEffects } from './workcards/workcards.effects'
import { WorkCardAccessorService } from '../widgets/staar/service-modules/data-accessors/work-card-accessor.service';
import { UserAccessorService } from '../widgets/staar/service-modules/data-accessors/user-accessor.service';
import { DataHelper } from '../widgets/staar/services/staar-utils'

@NgModule({
  imports: [
    applicationStoreForRoot(),
    EffectsModule.forRoot([WorkCardEffects]),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
      autoPause: true
    })
  ],
  providers: [
    DataHelper,
    UserAccessorService,
    WorkCardAccessorService,
  ]
})
export class ApplicationStoreModule {}
