export enum NetworkConnectivityState {
  noInternet = 'No Internet',
  noConnection = 'No Connection',
  ok = 'Okay',
}

export class NetworkOutageModel {
  private readonly networkOutageMessage = 'Poor internet connection detected. '
    + 'Features will be limited until you regain connection.';
  private readonly concourseOutageMessage = 'We\'re having issues connecting you to Concourse. '
    + 'Please get in touch with your local IT admin for support.';
  private hasDismissedNoInternetMessage = false;
  private hasDismissedNoConnectionMessage = false;
  private networkConnectivityState: NetworkConnectivityState = NetworkConnectivityState.ok;

  public get errorMessage(): string {
    switch (this.networkConnectivityState) {
      case NetworkConnectivityState.noInternet:
        return this.networkOutageMessage;
      case NetworkConnectivityState.noConnection:
        return this.concourseOutageMessage;
      case NetworkConnectivityState.ok:
        return '';
    }
  }

  public get hasDismissedError(): boolean {
    switch (this.networkConnectivityState) {
      case NetworkConnectivityState.noInternet:
        return this.hasDismissedNoInternetMessage;
      case NetworkConnectivityState.noConnection:
        return this.hasDismissedNoConnectionMessage;
      case NetworkConnectivityState.ok:
        return true;
    }
  }

  public get networkStatus(): NetworkConnectivityState {
    return this.networkConnectivityState;
  }

  public set networkStatus(value: NetworkConnectivityState) {
    if (this.networkConnectivityState === value) {
      return;
    }
    this.hasDismissedNoInternetMessage = false;
    this.hasDismissedNoConnectionMessage = false;
    this.networkConnectivityState = value;
  }

  public get isErrorMessageVisible(): boolean {
    switch (this.networkConnectivityState) {
      case NetworkConnectivityState.noInternet:
        return !this.hasDismissedNoInternetMessage;
      case NetworkConnectivityState.noConnection:
        return !this.hasDismissedNoConnectionMessage;
      case NetworkConnectivityState.ok:
        return false;
    }
  }

  public dismissError(): void {
    switch (this.networkConnectivityState) {
      case NetworkConnectivityState.ok:
        return;
      case NetworkConnectivityState.noInternet:
        this.hasDismissedNoInternetMessage = true;
        break;
      case NetworkConnectivityState.noConnection:
        this.hasDismissedNoConnectionMessage = true;
        break;
    }
  }
}