export interface Organization {
  id: number;
  name: string;
  organizationTypeId: number;
  isActive: boolean;
}

export class OrganizationUtil {
  static get localDevelopmentOrganizations(): Organization[] {
    const aarOrg: Organization = {
      "id": 4,
      "name": "AAR Corp",
      "organizationTypeId": 5,
      "isActive": true
    };
    return [
      aarOrg
    ];
  }
}
