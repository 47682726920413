import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { EntryPoints } from "../../../shared/models/entry-points";
import { EntryPointAccessGuard } from "../../../shared/services/entry-point-access-guard";
import { NgxIndexedDBService } from "ngx-indexed-db";
import { IndexDBService } from "../../../widgets/staar/services/indexDB.service/index-db.service";
import { RouteGuardCommunicationService } from "../../../shared/services/route-guard-communication.service";
import { Location } from "@angular/common";

@Injectable({ providedIn: 'root' })
export class OrgManagementAccessGuard extends EntryPointAccessGuard {
  public readonly targetEntryPoint = EntryPoints.orgManagement;

  constructor(
    public _indexDBService: IndexDBService,
    public dbService: NgxIndexedDBService,
    public location: Location,
    public routeGuardCommunicationService: RouteGuardCommunicationService
  ) {
    super(_indexDBService, dbService, location, routeGuardCommunicationService);
  }

  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    return this.doesHaveEntryPoint;
  }
}
