import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
@Injectable()
export class ErrorNotificationService {
    public subjectErr = new Subject<HttpErrorResponse>();
    private errObservable$ = this.subjectErr.asObservable();
    public emit(error: HttpErrorResponse): void {
        this.subjectErr.next(error);
    }

    public on(): Observable<HttpErrorResponse> {
        return this.errObservable$;
    }

}