export class workCardStepsModel {
    DIV_NO: number;
    PROJECT_NUMBER: string;
    WORK_ORDER_NUMBER: string;
    ZONE_NUMBER: string;
    ITEM_NUMBER: string;
    CARD_DESCRIPTION: string;
    CUSTOMER_WORK_CARD: string;
    WORK_CARD_ID: number;
    STEP_NUMBER: number;
    STEP_NAME: string;
    CURRENT_ACTIVITY_RESULT:string;
    DESCRIPTION: string;
    DOCUMENT_TYPE: string;
    PAGE_NUMBER: number;
    DOCUMENT_ITEM_NUMBER: string;
    CORRECTIVE_ACTION: string;
    STATUS: string;
    STATUS_REASON: string;
    CURRENT_ACTIVITY_EMPLOYEE: string;
    CURRENT_ACTIVITY_EMP_NAME: string;
    CURRENT_ACTIVITY_COMMENT: string;
    CURRENT_ACTIVITY_DATE: string;
    CURRENT_ACTIVITY_CODE: string;
    CURRENT_EMPLOYEE_FTY: string;
    CURRENT_EMPLOYEE_COLOR_CODE: string;
    PREVIOUS_ACTIVITY_EMPLOYEE: string;
    PREVIOUS_ACTIVITY_EMP_NAME: string;
    PREVIOUS_ACTIVITY_COMMENT: string;
    PREVIOUS_ACTIVITY_DATE: string;
    PREVIOUS_ACTIVITY_RESULT: string;
    PREVIOUS_ACTIVITY_CODE: string;
    PREVIOUS_EMPLOYEE_FTY: string;
    PREVIOUS_EMPLOYEE_COLOR_CODE: string;
    LEAD_SIGNOFF_FLAG: boolean;
    INSPECTOR_SIGNOFF_FLAG: string;
    RII_FLAG: boolean | string;
    SKILL_CODE_ID?: number;
    SKILL_CODE?: string;
    WORK_CENTER_ID: number;
    WORK_CENTER: string;
    WORK_CENTER_OPERATION_ID: number;
    OPERATION_CODE: number;
    ESTIMATED_HOURS: number;
    APPROVED_HOURS: number;
    COMPLETED_QUANTITY: number;
    SCRAPPED_QUANTITY: number;
    CREATED_BY_EMPLOYEE_ID: string;
    CREATED_BY_EMPLOYEE_NUMBER: string;
    CREATED_BY_EMPLOYEE_NAME: string;
    APPROVED_BY_EMPLOYEE_ID: string;
    APPROVED_BY_EMPLOYEE_NUMBER: string;
    APPROVED_BY_EMPLOYEE_NAME: string;
    SOURCE_CARD_ID: number;
    SOURCE_WORK_ORDER_NUMBER: number;
    SOURCE_ZONE_NUMBER: number;
    SOURCE_ITEM_NUMBER: number;
    TRANSFER_COUNT: number;
    SOURCE_CARD_STEP_ID: number;
    SOURCE_STEP_NUMBER: number;
    SOURCE_STEP_NAME: string;
    SOURCE_STEP_DESCRIPTION: string;
    CARD_STEP_ID: number;
    ACTIVE: string;
    PROGRAM_ID: number;
    TERMINAL_ID: number;
    DT_CREATED: string;
    USR_CREATED: string;
    DT_MODIFIED: string;
    FORMATTED_DT_MODIFIED?: string;
    USR_MODIFIED: string;
    TOKEN: string;
    REQUESTED_DATE?: string;
    REQUESTED_BY?: string;
    STAMPED_BY_INSPECTOR_FLAG: string;
    STAMPED_BY_MECHANIC_FLAG: string;
    STAMPED_BY_MECHANIC_DATE: string;
    STAMPED_BY_INSPECTOR_DATE: string;
    MECHANIC_STAMP_NUMBER?: string;
    INSPECTOR_STAMP_NUMBER?: string;
    STEP_ACTIVITY_STATUS?:string;
    MECHANIC_STAMP?:string;
    END_DATE?:string;
    LEAD_REJECT_FLAG?:string;
    CS_APPROVAL_REASON?: string;
    CS_DESCRIPTION?: string;
    CS_WORK_CARD_ID?: number;
    CS_CUSTOMER_CARD_STEPS_ID?: number;
    CS_PARENT_STEP_ID?: number;
    CS_DT_CREATED?: string;
    CS_CUSTOMER_ID?: number;
    CS_PERFORMED_BY_CUST_REP_NUMBER?: number;
    CS_DIV_NO?: number;
    CS_TOKEN?: number;
    CS_DT_MODIFIED_FORMATTED?: string;
    isNextToActivate?: boolean = false;
    WORK_ORDER_TYPE?: string;
    persona?: string
    CS_APPROVAL_STATUS?: string;
    CUST_REP_FIRST_NAME?: string;
    STAMPED_BY_MECHANIC_EMP_NAME?: string;
    CUST_REP_LAST_NAME?:string;
    custName?: string;
    //EIE properties
    STRIKE_OUT_FLAG?: string;
    EIE_STEPS?:workCardStepsModel[]=[];
    EIE_INDEX?:number;
    STEP_REJECT_COUNT?:number;
    
    PRIMARY_SKILL_CODE?: string;
    ALL_SKILL_CODES?: string;
    CREATED_BY_EMPLOYEE_FIRSTNAME?: string;
    CREATED_BY_EMPLOYEE_LASTNAME?: string;
    CREATED_BY_EMPLOYEE_TAG?: string;
    CREATED_DATE_FORMATTED?: string;
    APPROVED_BY_EMPLOYEE_FIRSTNAME?: string;
    APPROVED_BY_EMPLOYEE_LASTNAME?: string;
    REQUESTED_BY_FULL_NAME?: string;
    REQUESTED_BY_COLOR_CODE?: string;
    INSPECTED_BY_FULL?: string;
    INSPECTED_BY?: string;
    INSPECTED_DATE?: string;
    PROCESSED_BY_EMPLOYEE_ID?: number;
    PROCESSED_BY_EMPLOYEE_NUMBER?: string;
    PROCESSED_BY_EMPLOYEE_NAME?: string;
    STAMPED_BY_MECHANIC_EMP_NO?: string;
    STAMPED_BY_MECHANIC_EMP_ID?: number;  
    MECHANIC_STAMP_CATEGORY?: string;
    MECHANIC_STAMP_TYPE?: string;    
    STAMPED_BY_INSPECTOR_EMP_NO?: string;
    STAMPED_BY_INSPECTOR_EMP_ID?: number;
    STAMPED_BY_INSPECTOR_EMP_NAME?: string;
    INSPECTOR_STAMP_CATEGORY?: string;
    INSPECTOR_STAMP_TYPE?: string;
    STAMPED_BY_REP_FLAG?: string;
    STAMPED_BY_REP_DATE?: string;
    STAMPED_BY_REP_NUMBER?: string;
    STAMPED_BY_REP_ID?: number;
    STAMPED_BY_REP_NAME?: string;
    HANGAR?: string;
    BAY?: string;
    LATEST_STEP_DESCRIPTION?: string;
    LATEST_STEP_MODIFIED_DATE?: string;
    LATEST_STEP_MODIFIED_FDATE?: string;
    LATEST_STEP_MODIFIED_EMP_ID?: string;
    LATEST_STEP_MODIFIED_EMP_FNAME?: string;
    LATEST_STEP_MODIFIED_EMP_LNAME?: string;
    CUST_REP_APPROVAL_FLAG?: string;
    GROUP_ID?: number;
    REQUEST_TYPE?: string;
    SOURCE_STEP_ID?: number;
    SOURCE_STEP_ID_NAME?: string;
    SORT_ORDER?: string;
    STEP_INDEX?: number;
    APPROVAL_STATUS?: string;
    PARENT_STEP_ID?: number | string;
    PERFORMED_BY_CUST_REP_NUMBER?: number;
    DT_MODIFIED_FORMATTED?: string;
    CUSTOMER_CARD_STEPS_ID?: number;
    CUSTOMER_STEPS?: any;
    IS_NEWREQUEST?: boolean;
    INSPECTION_TYPE?: string;
    IS_EDITABLE?: boolean;
}

export class WorkCardStepsStatus {
    isEditable: boolean;
    technicianStatus: string;
    inspectorStatus: string;
    inspectorStatusEnabled: boolean;
}