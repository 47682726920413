import { MsalInterceptorConfiguration, MsalGuardConfiguration, } from '@azure/msal-angular';
import { IPublicClientApplication, PublicClientApplication, InteractionType, BrowserCacheLocation } from '@azure/msal-browser';
import { environment } from '../../../environments/environment';

export function MSALInstanceFactory(): IPublicClientApplication {
return new PublicClientApplication({
    auth: {
        clientId: environment.azureClient,
        authority: environment.azureAuthority,
        knownAuthorities: [environment.azureAuthority],
        redirectUri: environment.loginRedirectUri,
        postLogoutRedirectUri: environment.logoutRedirectUri,
        navigateToLoginRequestUrl: true,
    },
    cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage,
        storeAuthStateInCookie: true, // set to true for IE 11
    },
});
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
    const protectedResourceMap = new Map<string, Array<string>>();
    protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read']);
    return {
        interactionType: InteractionType.Redirect,
        protectedResourceMap,
    };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
    return {
        interactionType: InteractionType.Redirect,
        authRequest: {
            scopes: ['user.read']
        },
        loginFailedRoute: ''
    };
}
