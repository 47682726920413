import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { Screen, ScreenRoleConfiguration } from "../models/screen-role-configuration";
import { ScreenDefaultConfiguration } from "../models/screen-default-configuration";
import { ApiUtility } from "./api-utility";
import { HttpService } from "./http.service";
import { PersistedValueEnum } from "../models/persisted-value-enum";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: 'root',
})
export class ScreenAdminApiService {
  private readonly personaManagementPrefix = ApiUtility.personaManagementPrefix;
  modalData = {isModal :false ,assignedEmployeeList : '', }
  public employeeModal = new BehaviorSubject<boolean>(false);
  public employeeDetail = new BehaviorSubject<any>({});
  public getEmployeeList = new BehaviorSubject<any>(false);
  constructor(
    private httpService: HttpService,
  ) {}

  // Screen API's
  getAllScreens(): Observable<Screen[]> {
    return this.httpService.get(
      `${this.personaManagementPrefix}GetAllScreens`
    ) as Observable<Screen[]>;
  }

  insertScreen(screen: Screen): Observable<any> {
    return this.httpService.post(
      `${this.personaManagementPrefix}InsertScreen`,
      screen
    );
  }

  updateScreen(screen: Screen): Observable<any> {
    return this.httpService.post(
      `${this.personaManagementPrefix}UpdateScreen`,
      screen
    );
  }

  deleteScreenById(id: string | number): Observable<any> {
    return this.httpService.delete(
      `${this.personaManagementPrefix}DeleteScreenById/${id}`
    );
  }

  // Screen Role Configuration API's
  getScreenRoleConfigurationByOrgIdUserIdAndGuid(organizationId: string | number, userId: string | number, guid: string): Observable<ScreenRoleConfiguration> {
    return this.httpService.get(
      `${this.personaManagementPrefix}GetScreenRoleConfigurationByOrgIdUserIdAndGuid/${organizationId}/${userId}/${guid}`
    ) as Observable<ScreenRoleConfiguration>;
  }

  getAllScreenRoleConfigurations(): Observable<ScreenRoleConfiguration[]> {
    return this.httpService.get(
      `${this.personaManagementPrefix}GetAllScreenRoleConfigurations`
     ) as Observable<ScreenRoleConfiguration[]>;
  }

  getScreenRoleConfigurationByScreenId(screenId: string | number): Observable<ScreenRoleConfiguration> {
    return this.httpService.get(
      `${this.personaManagementPrefix}GetScreenRoleConfigurationByScreenId/${screenId}`
    ) as Observable<ScreenRoleConfiguration>;
  }

  getScreenRoleConfigurationByScreenIdAndRoleId(screenId: string | number, roleId: string | number, shouldFormatError: boolean = false): Observable<ScreenRoleConfiguration> {
    return this.httpService.get(
      `${this.personaManagementPrefix}GetScreenRoleConfigurationByScreenIdAndRoleId/${screenId}/${roleId}`, null, undefined, shouldFormatError
    ) as Observable<ScreenRoleConfiguration>;
  }

  insertScreenRoleConfiguration(screenRoleConfiguration: ScreenRoleConfiguration): Observable<any> {
    return this.httpService.post(
      `${this.personaManagementPrefix}InsertScreenRoleConfiguration`, screenRoleConfiguration
    );
  }

  updateScreenRoleConfiguration(screenRoleConfiguration: ScreenRoleConfiguration): Observable<any> {
    return this.httpService.post(
      `${this.personaManagementPrefix}UpdateScreenRoleConfiguration`, screenRoleConfiguration
    );
  }

  deleteScreenRoleConfigurationById(id: string | number): Observable<any> {
    return this.httpService.delete(
      `${this.personaManagementPrefix}DeleteScreenRoleConfigurationById/${id}`
    );
  }

  deleteScreenRoleConfigurationByGuid(guid: string | number): Observable<any> {
    return this.httpService.delete(
      `${this.personaManagementPrefix}DeleteScreenRoleConfigurationByGuid/${guid}`
    );
  }

  // Screen Default Configuration API's
  getAllScreenDefaultConfigurations(): Observable<ScreenDefaultConfiguration[]> {
    return this.httpService.get(
      `${this.personaManagementPrefix}GetAllScreenDefaultConfigurations`
     ) as Observable<ScreenDefaultConfiguration[]>;
  }

  getScreenDefaultConfigurationByScreenId(screenId: string | number, shouldFormatError: boolean = false): Observable<ScreenDefaultConfiguration> {
    return this.httpService.get(
      `${this.personaManagementPrefix}GetScreenDefaultConfigurationByScreenId/${screenId}`, null, undefined, shouldFormatError
    ) as Observable<ScreenDefaultConfiguration>;
  }

  insertScreenDefaultConfiguration(screenDefaultConfiguration: ScreenDefaultConfiguration): Observable<any> {
    return this.httpService.post(
      `${this.personaManagementPrefix}InsertScreenDefaultConfiguration`, screenDefaultConfiguration 
    );
  }

  updateScreenDefaultConfiguration(screenDefaultConfiguration: ScreenDefaultConfiguration): Observable<any> {
    return this.httpService.post(
      `${this.personaManagementPrefix}UpdateScreenDefaultConfiguration`, screenDefaultConfiguration
    );
  }

  deleteScreenDefaultConfigurationById(id: string | number): Observable<any> {
    return this.httpService.delete(
      `${this.personaManagementPrefix}DeleteScreenDefaultConfigurationById/${id}`
    );
  }

  deleteScreenDefaultConfigurationByGuid(guid: string | number): Observable<any> {
    return this.httpService.delete(
      `${this.personaManagementPrefix}DeleteScreenDefaultConfigurationByGuid/${guid}`
    );
  }

  /**
   * 
   * @param guid The guid of the screen configuration to query
   * @param callback A callback which receives the screen config and return true/false
   * @returns Observable<boolean> based on callback's result
   */
  checkScreenConfiguration(guid: string, callback: (screenConfig: any) => boolean): Observable<boolean> {
    const organizationId = Number(
      localStorage.getItem(PersistedValueEnum.currentOrganizationId)
    );
    const userId = Number(localStorage.getItem(PersistedValueEnum.userId));
    return this.getScreenRoleConfigurationByOrgIdUserIdAndGuid(
      organizationId,
      userId,
      guid
    ).pipe(
      map((res) => JSON.parse(res.screenConfiguration as string)),
      map(callback)
    )
  }
}
