import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { PersistedValueEnum } from "../models/persisted-value-enum";

@Injectable()
export class UserCredentialsGuard implements CanActivate {
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean|UrlTree>|Promise<boolean|UrlTree>|boolean|UrlTree {
    const userId = Number(localStorage.getItem(PersistedValueEnum.userId));
    const userIcon = localStorage.getItem(PersistedValueEnum.userInitials);
    const storedUserDetails = localStorage.getItem(PersistedValueEnum.userDetails);
    return userId !== null
      && userId !== undefined
      && userId > 0
      && userIcon !== null
      && userIcon !== undefined
      && storedUserDetails !== null
      && storedUserDetails !== undefined;
  }
}