import { createReducer, on, createAction, props } from '@ngrx/store';
import { IonicColor, ToastConfiguration } from 'aar-ionic-component-library/dist/ngx-aar-ionic-library';
import { Constants } from '../../widgets/staar/constants/mro-constants';

export type ReducerState = {
  toastConfig: ToastConfiguration;
};

const initialState: ReducerState = {
  toastConfig: {
    header: undefined,
    message: '',
    duration: 0,
    position: 'top',
    color: IonicColor.successSoft,
    buttons: []
  },
};

export const popToastError = createAction(
  '[Toast] Pop Toast Error',
  props<{ toast: Partial<ToastConfiguration> }>()
);

export const popToastSuccess = createAction(
  '[Toast] Pop Toast Success',
  props<{ toast: Partial<ToastConfiguration>}>()
)

const _toastReducer = createReducer<ReducerState>(
  initialState,
  on(popToastError, (state, { toast }) => ({ ...state, toastConfig: toastError(toast)})),
  on(popToastSuccess, (state, { toast }) => ({ ...state, toastConfig: toastSuccess(toast)}))
);

export const toastReducer = (state, action) => _toastReducer(state, action)

function toastSuccess(partialToast: Partial<ToastConfiguration>): ToastConfiguration {
  const defaults = {
    header: 'Success!',
    message: '',
    duration: Constants.durationForSuccess,
    position: partialToast.position ?? 'top',
    color: IonicColor.successSoft,
    buttons: []
  }
  return { ...defaults, ...partialToast }
}

function toastError(partialToast: Partial<ToastConfiguration>): ToastConfiguration {
  const defaults = {
    header: 'An Error Occurred',
    message: '',
    duration: Constants.durationForFailed,
    position: partialToast.position ?? 'top',
    color: IonicColor.dangerSoft,
    buttons: []
  }
  return { ...defaults, ...partialToast }
}
