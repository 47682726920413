import { Injectable } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { filter, pairwise } from "rxjs/operators";

@Injectable()
export class RouteTransitionService {

  public outgoingUrl: NavigationEnd = null;
  public incomingUrl: NavigationEnd = null;

  private routingSubscription: Subscription = null;
  
  constructor(
    private router: Router
  ) {}

  subscribe() {
    this.routingSubscription = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      pairwise()
    ).subscribe(([outgoing, incoming]: [NavigationEnd, NavigationEnd]) => {
      this.incomingUrl = incoming 
      this.outgoingUrl = outgoing
    });
  }

  unsubscribe() {
    this.routingSubscription?.unsubscribe();
  }
}