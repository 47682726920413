export const Constants = {
    lead: 'lead',
    technician: 'technician',
    inspector: 'inspector',
    customer: 'customer',
    durationForSuccess: 3000,
    durationForFailed: 5000,
    successMsg: 'Your changes were successfully saved.',
    emptyTableContant: 'No work card currently assigned. Contact your Lead for further assistance',
    dateFormat_MMddyyyy: 'MM/dd/yyyy',
    attachmentRemoved: 'Attachment removed',
    attachmentSaved: 'Attachment saved',
    workcardSaved: 'Work Card File Saved',
    workCardObjectType: 'WORK-CARD',
    myCardsDescriptionLength: 55,
    myShortCardsDescriptionLength: 22,
    noPDFTronData: 'No work card file is attached.',
    noPartsData: 'Parts data are not available',
    noToolsData: 'No tools added to work card',
    pdfTronSupportedExts: ['PDF', 'XFDF', 'FDF',
        'DOC', 'DOCX', 'XLS', 'XLSX', 'PPT', 'PPTX', 'VSD', 'VSDX', 'PUB', 'DWG', 'DWF',
        'DXF', 'DGN', 'RVT', 'SVG', 'BMP', 'WMF', 'EMF', 'GIF', 'JPG', 'JP2', 'JPC', 'PNG',
        'JFIF', 'TIF', 'AAI', 'ARW', 'CR2', 'CRW', 'CUR', 'ICO', 'DCM', 'DCR', 'DDS', 'HEIC',
        'HEIF', 'MRW', 'NEF', 'ORF', 'PICT', 'PFM', 'PSB', 'PSD', 'RAF', 'RTF', 'ODT', 'ODS',
        'ODP', 'HTML', 'MSG', 'EML', 'TXT'],
    unsupportedFileType: 'File type is not supported.',
    splitSuccessHeader: 'Automatic splitting model is complete.',
    splitSuccessMessage: 'Click the "Edit Document Splitting Model" button in Project Files tab to finalize',
    splitErrorMessage: 'Error occurred while executing split.',
    noInspectionQueueData: 'No data for inspection.',
    inspectionReqSuccessMsg: 'Inspection request sent successfully.',
    dateTimeFormat_MMddyyyy_hhmmss: 'MM/dd/yyyy HH:mm:ss',
    emptyTable: 'No data found.',
    createDiscrepancySuccessMsg: 'A new non-routine work card was successfully created.',
    splitTypeAutomatic: 'automatic',
    splitTypeManual: 'manual',
    emptyTableAttachment: 'No attachments',
    updateDiscrepancySuccessMsg: 'Discrepancy updated successfully.',
    releaseDiscrepancySuccessMsg: 'Discrepancy released successfully.',
    noFoldersData: 'Folder(s) are not available.',
    newStepAddSuccessMsg: 'Work step added successfully.',
    stepUpdateSuccessMsg: 'Work step updated successfully.',
    newCirStepAddSuccessMsg: 'CIR step added successfully.',
    cirStepUpdateSuccessMsg: 'CIR step updated successfully.',
    customerAcceptWorkSuccessMsg: 'Work accepted successfully.',
    assignmentPublished: 'Assignments successfully published.',
    saveNRCSuccessMsg: 'NR evaluation saved',
    cardSubmittedForClosureReview: 'Card submitted for closure review',
    primarySkillUpdateSuccessMsg: 'Skill updated successfully.',
    startClockMsg: 'Please start the clock',
    eieStepCreatedSuccessMsg: 'EIE step created successfully.',
    eieStepUpdatedSuccessMsg: 'EIE step updated successfully.',
    createTurnOverReportSuccessMsg: 'Turnover report successfully created.',
    createTurnOverReportEditSuccessMsg: 'Turnover report saved',
    AcceptTurnOverReportSuccessMsg: 'Turnover report successfully accepted.',
    keyNumber:'0123456789abcdef0123456789abcdef',
    referenceProjectMappingSuccess: 'Applied a Target Start to [updatecount] work cards <br> - [differencecount] routine work cards were unable to be matched (e.g. new cards since last visit) <br>- Total cards in selected project [totalcount] (includes NRCs)',
    targetStartDaysSetSuccessMsg: 'Target start day updated successfully.',
    cirStepVoidSuccessMsg: 'CIR step voided successfully.',
    logPageCreatedSuccessMsg: 'A log page was added to work card ',
    logPageDeletedSuccessMsg: 'A log page was removed from work card ',
    inspectionReqUpdatedSuccessMsg: 'Inspection request updated successfully',
    inspectionReqCancelSuccessMsg: 'Inspection request cancelled successfully',
    transferClockNoDataMsg: `No work cards with previously tracked labor this shift. You'll need to find a new work card to start tracking labor on in order to stop labor here (In-work Projects or My Work Cards).`,
    durationForSuccessfullInspection: 8000,
}