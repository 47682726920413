import { cloneDeep, keyBy } from 'lodash';
import { createReducer, on } from '@ngrx/store';
import { ProjectDetailsWorkCardModel } from '../../widgets/staar/models/projectdetails-workcard';
import {
  addWorkCards,
  deleteAnnotation,
  patchWorkCard,
  setAnnotation,
  setAttachments,
  setAuditClosureReviews,
  setAuditQueue,
  setCIRSteps,
  setCustomerWorkCardQueue,
  setLogPages,
  setNRCSteps,
  setParts,
  setPreAuditChecklist,
  setRoutineFlags,
  setSceptreRequired,
  setSelectedWorkCard,
  setTechData,
  setTools,
  setWorkCardFile,
  setWorkCards,
  setCircuitBreakers
} from './workcards.actions';
import {
  AuditClosureReviewResModel,
  RoutineInspectionFlagResModel,
} from '../../widgets/staar/models/request-payload';
import { WorkCardFilesModel } from '../../widgets/staar/models/project-files';
import { RequiredPartsModel } from '../../widgets/staar/models/my-work-card';
import { ToolsModel } from '../../widgets/staar/models/tools-history';
import { workCardStepsModel } from '../../widgets/staar/models/workCardSteps';
import { BasicAnnotation, CIRStep, PreAuditChecklistItem } from '../../widgets/staar/service-modules/data-accessors/work-card-accessor.service';
import { GetLogPageNumbersResponseModel } from '../../widgets/staar/models/log-page-numbers';
import { CircuitBreakerModel } from '../../widgets/staar/models/circuit-breaker';

export type ReducerState = {
  workCards: Record<string, ProjectDetailsWorkCardModel>;
  selectedWorkCardId: number;

  // this record is indexed by the work card id
  routineFlags: Record<string, RoutineInspectionFlagResModel[]>;
  cirSteps: Record<string, CIRStep[]>;
  attachments: Record<string, WorkCardFilesModel[]>;

  annotations: {
    [workCardId: string]: {
      [fileObjectId: string]: {
        [annotationId: string]: BasicAnnotation;
      };
    };
  };
  workCardFiles: Record<string, WorkCardFilesModel>;
  techData: Record<string, WorkCardFilesModel[]>;
  nrcSteps: Record<string, workCardStepsModel[]>;

  auditQueue: number[]; // List of WORK_CARD_IDs
  customerWorkCardQueue: number[]; // List of WORK_CARD_IDs
  parts: Record<string, RequiredPartsModel[]>;
  tools: Record<string, ToolsModel[]>;
  sceptreDetails: Record<string, boolean>;

  preAuditChecklist: Record<string, PreAuditChecklistItem[]>;
  logPages: Record<string, GetLogPageNumbersResponseModel[]>;
  auditClosureReviews: Record<string, AuditClosureReviewResModel[]>;
  circuitBreakers: Record<string, CircuitBreakerModel[]>;
};

const initialState: ReducerState = {
  workCards: {},
  selectedWorkCardId: null,
  routineFlags: {},
  cirSteps: {},
  attachments: {},
  annotations: {},
  workCardFiles: {},
  techData: {},
  nrcSteps: {},

  auditQueue: [],
  customerWorkCardQueue: [],
  parts: {},
  tools: {},
  sceptreDetails: {},

  preAuditChecklist: {},
  logPages: {},
  auditClosureReviews:{},
  circuitBreakers:{}
};

const _workCardsReducer = createReducer<ReducerState>(
  initialState,

  // Accepts either a full work card object, or just the ID.
  on(setSelectedWorkCard, (state, { workCard, workCardId }) => {
    const selectedWorkCardId = workCardId
      ? +workCardId
      : workCard?.WORK_CARD_ID;
    const workCards = { ...state.workCards };

    if (workCard) {
      workCards[selectedWorkCardId] = workCard;
    }

    return { ...state, workCards, selectedWorkCardId };
  }),

  on(setWorkCards, (state, { workCards }) => ({
    ...state,
    workCards: keyBy(workCards, 'WORK_CARD_ID'),
  })),

  on(addWorkCards, (state, { workCards }) => {
    const newWorkCards = keyBy(
      workCards.map((o) => ({ ...o })),
      'WORK_CARD_ID'
    );
    return {
      ...state,
      workCards: {
        ...state.workCards,
        ...newWorkCards,
      },
    };
  }),

  on(patchWorkCard, (state, { workCardId, patch }) => {
    const existingWorkCard = state.workCards[workCardId];
    if (!existingWorkCard) {
      return state;
    }

    const updatedWorkCard = { ...existingWorkCard, ...patch };
    return {
      ...state,
      workCards: {
        ...state.workCards,
        [workCardId]: updatedWorkCard,
      },
    };
  }),

  on(setRoutineFlags, (state, { workCardId, flags }) => {
    return {
      ...state,
      routineFlags: {
        ...state.routineFlags,
        [workCardId]: flags,
      },
    };
  }),

  on(setCIRSteps, (state, { workCardId, steps }) => {
    return {
      ...state,
      cirSteps: {
        ...state.cirSteps,
        [workCardId]: steps,
      },
    };
  }),

  on(setAttachments, (state, { workCardId, attachments }) => {
    return {
      ...state,
      attachments: {
        ...state.attachments,
        [workCardId]: attachments,
      },
    };
  }),



  on(setWorkCardFile, (state, { workCardId, file }) => {
    return {
      ...state,
      workCardFiles: {
        ...state.workCardFiles,
        [workCardId]: file,
      },
    };
  }),

  on(setTechData, (state, { workCardId, techData }) => {
    return {
      ...state,
      techData: {
        ...state.techData,
        [workCardId]: techData,
      },
    };
  }),

  on(setNRCSteps, (state, { workCardId, nrcSteps }) => {
    return {
      ...state,
      nrcSteps: {
        ...state.nrcSteps,
        [workCardId]: nrcSteps,
      },
    };
  }),

  on(setAuditQueue, (state, { auditQueue }) => {
    return {
      ...state,
      auditQueue,
    };
  }),

  on(setCustomerWorkCardQueue, (state, { queue }) => {
    return {
      ...state,
      customerWorkCardQueue: queue,
    };
  }),

  on(setParts, (state, { workCardId, parts }) => {
    return {
      ...state,
      parts: {
        ...state.parts,
        [workCardId]: parts,
      },
    };
  }),

  on(setTools, (state, { workCardId, tools }) => {
    return {
      ...state,
      tools: {
        ...state.tools,
        [workCardId]: tools,
      },
    };
  }),

  on(setSceptreRequired, (state, { workCardId, sceptreRequired }) => {
    return {
      ...state,
      sceptreDetails: {
        ...state.sceptreDetails,
        [workCardId]: sceptreRequired,
      },
    };
  }),

  on(setAnnotation, (state, { fileObject, annotation }): ReducerState => {
    const { PARENT_ID: workCardId, ID: fileObjectId } = fileObject;
    const { annotationId } = annotation;

    const existingWorkCards = state.annotations ?? {};
    const existingFileObjects = existingWorkCards[workCardId] ?? {};
    const existingAnnotations = existingFileObjects[fileObjectId] ?? {};

    return {
      ...state,
      annotations: {
        ...existingWorkCards,
        [workCardId]: {
          ...existingFileObjects,
          [fileObjectId]: {
            ...existingAnnotations,
            [annotationId]: annotation,
          },
        },
      },
    };
  }),

  on(deleteAnnotation, (state, { fileObject, annotation }): ReducerState => {
    const { PARENT_ID: workCardId, ID: fileObjectId } = fileObject;
    const { annotationId } = annotation;

    const existingWorkCards = state.annotations ?? {};
    const existingFileObjects = existingWorkCards[workCardId] ?? {};
    const existingAnnotations =
      cloneDeep(existingFileObjects[fileObjectId]) ?? {};

    delete existingAnnotations[annotationId];

    return {
      ...state,
      annotations: {
        ...existingWorkCards,
        [workCardId]: {
          ...existingFileObjects,
          [fileObjectId]: {
            ...existingAnnotations,
          },
        },
      },
    };
  }),

  on(setPreAuditChecklist, (state, { workCardId, preAuditChecklist }) => {
    return {
      ...state,
      preAuditChecklist: {
        ...state.preAuditChecklist,
        [workCardId]: preAuditChecklist
      }
    }
  }),

  on(setLogPages, (state, { workCardId, logPages }) => {
    return {
      ...state,
      logPages: {
        ...state.logPages,
        [workCardId]: logPages
      }
    }
  }),

  on(setAuditClosureReviews, (state, { workCardId, auditReviews }) => {
    return {
      ...state,
      auditClosureReviews: {
        ...state.auditClosureReviews,
        [workCardId]: auditReviews
      }
    }
  }),

  on(setCircuitBreakers, (state, { workCardId, circuitBreakers }) => {
    return {
      ...state,
      circuitBreakers: {
        ...state.circuitBreakers,
        [workCardId]: circuitBreakers
      }
    }
  })
);

export const workCardsReducer = (state, action) =>
  _workCardsReducer(state, action);
