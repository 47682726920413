import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { EntryPoints } from "../models/entry-points";
import { IndexDBService } from "../../widgets/staar/services/indexDB.service/index-db.service";
import { NgxIndexedDBService } from "ngx-indexed-db";
import { take } from "rxjs/operators";
import { Widget } from "../models/widget";
import { RouteGuardCommunicationService } from "./route-guard-communication.service";
import { Location } from "@angular/common";

@Injectable()
export abstract class EntryPointAccessGuard implements CanActivate {
  public abstract targetEntryPoint: EntryPoints;

  constructor(
    public _indexDBService: IndexDBService,
    public dbService: NgxIndexedDBService,
    public location: Location,
    public routeGuardCommunicationService: RouteGuardCommunicationService
  ) {}

  public abstract canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean|UrlTree>|Promise<boolean|UrlTree>|boolean|UrlTree;


  public get doesHaveEntryPoint(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.dbService.getAll('widgetDashboard')
        .pipe(take(1))
        .subscribe(
          (widgetList: [Widget]) => {
            const hasEntryPoint = widgetList.filter((widget: Widget) => {
              return widget.widgetName === this.targetEntryPoint;
            }).length > 0;
            if (!hasEntryPoint) {
                // If access should be denied
                this.routeGuardCommunicationService.displayAccessDeniedToast.next(true);
                setTimeout(() => {
                    this.location.back();
                    resolve(hasEntryPoint);
                }, 2000)
            } else {
                resolve(hasEntryPoint);
            }
          },
          (error) => {
            console.error(error);
            reject(false);
          }
        );
    });
  }
}