import { Injectable } from '@angular/core';
import { ApplicationState } from '../../store/application-store';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { selectedWorkCard } from '../../store/selectors';

@Injectable()
export class AptyService {
  private readonly workCardKey = 'apty_workCardDetails';
  private subscriptions: Subscription[] = [];

  constructor(private applicationStore: Store<ApplicationState>) {}

  subscribe() {
    this.subscriptions.push(
      this.applicationStore.select(selectedWorkCard).subscribe((workCard) => {
        localStorage.setItem(this.workCardKey, JSON.stringify(workCard));
      })
    );
  }

  unsubscribe() {
    this.subscriptions.forEach((s) => s?.unsubscribe());
  }
}
