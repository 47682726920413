import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable()
export class ApimInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.indexOf(environment.apimBaseUrl) < 0) {
      return next.handle(request);
    }
    let authenticatedRequest = request.clone({
      setHeaders: {
        'Ocp-Apim-Subscription-Key': environment.apimSubscriptionKey
      }
    });
    return next.handle(authenticatedRequest);
  }
}
