export enum EntryPoints {
  // MRO
  myWorkCards = 'My Work Cards',
  inWorkProjects = 'In-Work Projects',
  workPackagageMaintenance = 'Work Package Maintenance',
  trainingDocs = 'Training Documents',
  inspectionQueue = 'Inspection Queue',
  mroCustomer = 'MRO Customer',
  mroProjectManagement = 'MRO Project Manager',
  cardClosureQueue = 'Card Closure Queue',
  kickbackQueue = 'Kickback Queue',
  discrepancyQueue = 'Discrepancy Queue',
  recordsQueue = 'Records Queue',
  // NGE
  catalogManagement = 'Catalog Management',
  ediExceptions = 'EDI Exceptions Tool',
  checkPricing = 'Check Pricing',
  pricingExport = 'Pricing Export Tool',
  bulkUpload = 'Bulk Upload',
  customerTiers = 'Customer Tiers',
  salesCatalog = 'Sales Catalog',
  componentTeardown = 'Component Teardown',
  // Admin
  userManagement = 'User Management',
  orgManagement = 'Organization & Role Management',
  customerManagement = 'Customer Management',
}
