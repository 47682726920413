import { DBConfig } from "ngx-indexed-db";

const dbConfig: DBConfig = {
  name: 'workCardDb',
  version: 7,
  objectStoresMeta: [
    {
      store: 'widgetDashboard',
      storeConfig: { keyPath: 'id', autoIncrement: true },
      storeSchema: []
    },  
    {
      store: 'myworkCardNumber',
      storeConfig: { keyPath: 'id', autoIncrement: true },
      storeSchema: []
    }, 
    {
      store: 'myworkCardlist',
      storeConfig: { keyPath: 'id', autoIncrement: true },
      storeSchema: []
    }, 
    {
      store: 'myWorkProject',
      storeConfig: { keyPath: 'id', autoIncrement: true },
      storeSchema: []
    }, 
    {
      store: 'cardClosureQueueList',
      storeConfig: { keyPath: 'id', autoIncrement: true },
      storeSchema:[]
    }, 
    {
      store: 'kickbackData',
      storeConfig: { keyPath: 'id', autoIncrement: true },
      storeSchema:[]
    },
  ], 
};

export default dbConfig;