<ion-app [class.dark-theme]="dark">
  <lib-aar-toast [config]="toastNotification" [displayToastSubject]="displayToastSubject"></lib-aar-toast>
  <ion-split-pane contentId="main-content">
    <ion-router-outlet id="main-content"></ion-router-outlet>
  </ion-split-pane>
  <div class="error-banner" *ngIf="networkOutageModel.isErrorMessageVisible === true">
    <lib-aar-label>{{ networkOutageModel.errorMessage }}</lib-aar-label>
    <lib-aar-button
      [config]="closeButtonConfig"
      (click)="hideError()">
    </lib-aar-button>
  </div>
</ion-app>   

 