import { StoreModule } from '@ngrx/store';
import {
  workCardsReducer,
  ReducerState as WorkCardState,
} from './workcards/workcards.reducer';
import { projectsReducer, ReducerState as ProjectsState } from './projects/projects.reducer'
import { usersReducer, ReducerState as UsersState } from './users/users.reducer'

import {
  toastReducer,
  ReducerState as ToastState,
  popToastError,
  popToastSuccess,
} from './toast/toast';

export type ApplicationState = {
  projects: ProjectsState;
  toast: ToastState;
  users: UsersState;
  workCards: WorkCardState;
};

export const reducerMap = {
  projects: projectsReducer,
  toast: toastReducer,
  users: usersReducer,
  workCards: workCardsReducer,
};

export function applicationStoreForRoot(reducers = reducerMap) {
  return StoreModule.forRoot(reducers);
}

export const toastActions = {
  popToastError,
  popToastSuccess,
};

export * as workCardActions from './workcards/workcards.actions';
export * as projectActions from './projects/projects.actions';
export * as userActions from './users/users.actions';
export * as selectors from './selectors';
