import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from "@angular/router";
import { OrgManagementAccessGuard } from "./org-management-access-guard";
import { UserManagementAccessGuard } from "./user-management-access-guard";

@Injectable({ providedIn: 'root' })
export class AdminManagementAccessGuard implements CanActivate {
  constructor(
    private orgManagementGuard: OrgManagementAccessGuard,
    private userManagementGuard: UserManagementAccessGuard,
  ) { }

  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {
      Promise
        .all([
          this.orgManagementGuard.canActivate(route, state),
          this.userManagementGuard.canActivate(route, state),
        ])
        .then((results: [boolean, boolean]) => {
          resolve(results[0] || results[1]);
        })
        .catch((error) => {
          console.error(error);
          reject(false);
        });
    });
  }
}
