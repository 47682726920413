import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { RoutineActivityStampManageModel } from '../models/employee-stamps';
import { ClosureQueueShareDataModel, InspectionQueueShareDataModel } from '../models/project-details';

@Injectable({
  providedIn: 'root'
})
export class ProjectManagementService {

  projectDetailsBehave = new BehaviorSubject<Object>({ ProjectBehave: 'ProjectBehave' });
  workCardDetailsBehave = new BehaviorSubject<Object>({ WorkCardBehave: 'WorkCardBehave' });
  workPackageManagerBehave = new BehaviorSubject<Object>({ WorkPackageBehave: 'WorkPackageBehave' });
  WPMWorkCardDetailsBehave = new BehaviorSubject<Object>({ WPMWorkCardBehave: 'WPMWorkCardBehave' });
  private customerReviewStatusDataEvent = new Subject<string>();
  private partialWorkStepStatusDataEvent = new Subject<boolean>();
  private laborClockMyWorkCardDataEvent = new Subject<boolean>();
  private recordQueueDivisionChangeDataEvent = new Subject<string>();
  public customerReviewStatusDataEventObservable$ = this.customerReviewStatusDataEvent.asObservable();
  public partialWorkStepStatusDataEventObservable$ = this.partialWorkStepStatusDataEvent.asObservable();
  public laborClockMyWorkCardDataEventObservable$ = this.laborClockMyWorkCardDataEvent.asObservable();
  public recordQueueDivisionChangeDataEventObservable$ = this.recordQueueDivisionChangeDataEvent.asObservable();
  private onStampAppliedSubj = new Subject<boolean>();
  public onStampAppliedObservable = this.onStampAppliedSubj.asObservable();
  private onSubmitRoutineActivitySubj = new Subject<RoutineActivityStampManageModel>();
  public onSubmitRoutineActivityObservable = this.onSubmitRoutineActivitySubj.asObservable();
  private inspectionQueueShareDataSub = new Subject<InspectionQueueShareDataModel>();
  public inspectionQueueShareDataObservable = this.inspectionQueueShareDataSub.asObservable();
  private closureQueueShareDataSub = new Subject<ClosureQueueShareDataModel>();
  public closureQueueShareDataObservable = this.closureQueueShareDataSub.asObservable();
  private refreshDataEvent = new Subject<string>();
  public refreshsDataEventObservable$ = this.refreshDataEvent.asObservable();
  private updateWCStatusAndReasonDataEvent = new Subject<string>();
  public updateWCStatusAndReasonDataEventObservable$ = this.updateWCStatusAndReasonDataEvent.asObservable();
  private refereshProfileViewHangerMechSub = new Subject<any>();
  public refereshProfileViewHangerMechObservable = this.refereshProfileViewHangerMechSub.asObservable();
  public isContinuationSheet:string='';
  public isInspectionSubmit :boolean;
  public isNonContinuationRequest : boolean;
  setProjectIdentificationDetails(ProjectDetailsBehave: Object): void {
    this.projectDetailsBehave.next(ProjectDetailsBehave);
  }

  getProjectIdentificationDetails(): Observable<any> {
    return this.projectDetailsBehave.asObservable();
  }

  setWorkCardIdentificationDetails(WorkCardDetailsBehave: Object): void {
    this.workCardDetailsBehave.next(WorkCardDetailsBehave);
  }

  getWorkCardIdentificationDetails(): Observable<any> {
    return this.workCardDetailsBehave.asObservable();
  }

  setWorkPackageManagerDetails(workPackageManagerBehave: Object): void {
    this.workPackageManagerBehave.next(workPackageManagerBehave);
  }

  getWorkPackageManagerDetails(): Observable<any> {
    return this.workPackageManagerBehave.asObservable();
  }

  setWPMWorkCardsDetails(WPMWorkCardDetailsBehave: Object): void {
    this.WPMWorkCardDetailsBehave.next(WPMWorkCardDetailsBehave);
  }

  getWPMWorkCardsDetails(): Observable<any> {
    return this.WPMWorkCardDetailsBehave.asObservable();
  }

  public updateCustomerReviewStatus(status: string): void {
    this.customerReviewStatusDataEvent.next(status);
  }

  public updatePartialWorkStepStatus(status: boolean): void {
    this.partialWorkStepStatusDataEvent.next(status);
  }

  public onStampApplied(isStamped: boolean): void {
    this.onStampAppliedSubj.next(isStamped);
  }

  public onSubmitRoutineActivity(data: RoutineActivityStampManageModel): void {
    this.onSubmitRoutineActivitySubj.next(data);
  }

  public refreshMyWorkCardLaborClockStatus(status: boolean): void {
    this.laborClockMyWorkCardDataEvent.next(status);
  }

  public shareInspectionQueueData(data: InspectionQueueShareDataModel): void {
    this.inspectionQueueShareDataSub.next(data);
  }

  public shareClosureQueueData(data: ClosureQueueShareDataModel): void {
    this.closureQueueShareDataSub.next(data);
  }

  public refreshData(refreshFor: string): void {
    this.refreshDataEvent.next(refreshFor);
  }

  public refreshRecordQueueData(type: string): void {
    this.recordQueueDivisionChangeDataEvent.next(type);
  }

  public updateWCStatusAndReason(status: string): void {
    this.updateWCStatusAndReasonDataEvent.next(status);
  }

  public refreshProfileViewHangerMech(data: any): void {
    this.refereshProfileViewHangerMechSub.next(data);
  }
}
