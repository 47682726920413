import { Injectable } from '@angular/core';
import { NgxIndexedDBService} from 'ngx-indexed-db';

@Injectable({
  providedIn: 'root'
})
export class IndexDBService {

  constructor(private dbService: NgxIndexedDBService) { }

  public addIndexDB(dbName:string, data:any){
    this.dbService.add(dbName, data).subscribe((key) => {
    });
  }

  public addBulkIndexDB(dbName: string, data: any) {
    this.dbService.bulkAdd(dbName, data)
      .subscribe((result) => {
      });
  }

  public clearIndexDB(dbName: string) {
    this.dbService.clear(dbName).subscribe((successDeleted) => {
    });
  }

  public addandUpdateIndexDB(dbName: string, data: any) {
    this.dbService.update(dbName, data).subscribe((storeData) => {
    });
  }

  public getAllDataIndexDB(dbName: string) {   
   return this.dbService.getAll(dbName);
  }
}
