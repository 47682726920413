export const environment = {
  production: true,
  isLocal: '##{isLocal}##',
  isAptyEnabled: '##{isAptyEnabled}##',

  azureClient: '##{azureClient}##',
  azureAuthority: '##{azureAuthority}##',

  domoEmbedDashboardPath: '##{domoEmbedDashboardPath}##',

  loginRedirectUri: '##{loginRedirectUri}##',
  logoutRedirectUri: '##{logoutRedirectUri}##',

  quoteAcceleratorBaseUrl: '##{quoteAcceleratorBaseUrl}##',

  apimBaseUrl: '##{apimBaseUrl}##',
  apimSubscriptionKey: '##{apimSubscriptionKey}##',

  newRelicDashboardId: "##{newRelicDashboardId}##"
};
