import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { EntryPoints } from "../../../../shared/models/entry-points";
import { StaarEntryPointAccessGuardService } from "../../services/staar-entry-point-access-guard.service";

@Injectable({ providedIn: 'root' })
export class RecordsQueueRouteGuard extends StaarEntryPointAccessGuardService {
  public readonly targetEntryPoint = EntryPoints.recordsQueue;

  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    return this.doesHaveEntryPoint;
  }
}