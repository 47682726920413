import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HttpService {
  apiUrl: string;
  httpOptions: { headers: HttpHeaders; };
  // To be used as a test api call with the api-error-response-test.service
  errorTestOptions =  { 
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  };

  constructor(
    private http: HttpClient,
  ) {
    this.apiUrl = environment.apimBaseUrl;
    this.setHeader();
  }

  setHeader(): void {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: '*/*',
      })
    };
  }
  
  private formatErrors(error: any): Observable<never> {
    return throwError(error.error);
  }

  get(
    path: string,
    headers: HttpHeaders = null,
    params: HttpParams = new HttpParams(),
    shouldFormatError: boolean = true,
    errorHandler: (error: any) => Observable<any> = null,
  ): Observable<any> {
    let getHeaders = this.httpOptions.headers;
    if (headers !== null) {
      for (let key of headers.keys()) {
        getHeaders = getHeaders.set(key, headers.get(key));
      }
    }
    return this.http.get(`${this.apiUrl}${path}`, { headers: getHeaders, params })
      .pipe(
        catchError(err => errorHandler ? errorHandler(err) : throwError(err)),
        catchError(err => shouldFormatError === true ? this.formatErrors(err) : throwError(err))
      );
  }

  post(
    path: string,
    body: any = null,
    headers: HttpHeaders = null,
    params: HttpParams = new HttpParams(),
    shouldFormatError: boolean = true,
  ): Observable<any> {
    let postHeaders = this.httpOptions.headers;
    if (headers !== null) {
      for (let key of headers.keys()) {
        postHeaders = postHeaders.set(key, headers.get(key));
      }
    }
    return this.http
      .post(`${this.apiUrl}${path}`, body, { headers: postHeaders, params: params })
      .pipe(
        catchError(err => shouldFormatError === true ? this.formatErrors(err) : throwError(err))
      );
  }

  delete(
    path: string,
    body: any = null,
    headers: HttpHeaders = null,
    params: HttpParams = new HttpParams(),
    shouldFormatError: boolean = true,
  ): Observable<any> {
    let deleteHeaders = this.httpOptions.headers;
    if (headers !== null) {
      for (let key of headers.keys()) {
        deleteHeaders = deleteHeaders.set(key, headers.get(key));
      }
    }
    return this.http
      .delete(`${this.apiUrl}${path}`, { headers: deleteHeaders, params: params })
      .pipe(
        catchError(err => shouldFormatError === true ? this.formatErrors(err) : throwError(err))
      );
  }

  postFormData(
    path: string,
    body: any = null,
    headers: HttpHeaders = null,
    params: HttpParams = new HttpParams(),
    shouldFormatError: boolean = true,
  ): Observable<any> {
    let postHeaders = new HttpHeaders({
      Accept: '*/*',
    });
    return this.http
      .post(`${this.apiUrl}${path}`, body, { headers: postHeaders, params: params })
      .pipe(
        catchError(err => shouldFormatError === true ? this.formatErrors(err) : throwError(err))
      );
  }

  getBlob(
    path: string,
    params: HttpParams = new HttpParams(),
    shouldFormatError: boolean = true,
  ): Observable<any> {
    return this.http.get(`${this.apiUrl}${path}`, { headers: this.httpOptions.headers, params, responseType: 'blob' })
      .pipe(
        catchError(err => shouldFormatError === true ? this.formatErrors(err) : throwError(err))
      );
  }

  /** task 20211 CIR Desginator Stamp */
  postCir(
    path: string,
    responseType,
    params: HttpParams = new HttpParams(),
    shouldFormatError: boolean = true,
  ): Observable<any> {
    return this.http.post(`${this.apiUrl}${path}`, null, { headers: this.httpOptions.headers, responseType: responseType, params })
      .pipe(
        catchError(err => shouldFormatError === true ? this.formatErrors(err) : throwError(err))
      );
  }

  // To be used with the api-error-response-test.service
  testErrorGet(
    path: string,
    params: HttpParams = new HttpParams(),
    shouldFormatError: boolean = true,
  ): Observable<any> {
    return this.http.get(path, { headers: this.errorTestOptions.headers, params })
      .pipe(
        catchError(err => shouldFormatError === true ? this.formatErrors(err) : throwError(err))
      );
  }

  postBlob(
    path: string,
    body: any = null,
    headers: HttpHeaders = null,
    params: HttpParams = new HttpParams(),
    errorHandler: (error: any) => Observable<any> = null,
  ): Observable<any> {
    let postHeaders = this.httpOptions.headers;
    if (headers !== null) {
      for (let key of headers.keys()) {
        postHeaders = postHeaders.set(key, headers.get(key));
      }
    }
    return this.http
      .post(`${this.apiUrl}${path}`, body, { headers: postHeaders, params: params, responseType: 'blob' })
      .pipe(
        catchError(err => errorHandler ? errorHandler(err) : throwError(err)),
        catchError(this.formatErrors)
      );
  }
}
