export const PersistedValueEnum = Object.freeze({
  userInitials: 'userInitials',
  userId: 'userId',
  userDetails: 'userDetails',
  currentOrganizationId: 'currentOrganizationId',
  currentOrganizationName: 'currentOrganizationName',
  currentDomoDashboardId: 'currentDomoDashboardId',
  currentWidget: 'currentWidget',
  userAzureId: 'userAzureId',
  userAzureAccessToken: 'userAzureAccessToken',
});
