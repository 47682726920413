import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError ,Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { ErrorNotificationService } from './error-notification.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private errorNotificationService: ErrorNotificationService) { }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request) //it sends the updated request
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    this.errorNotificationService.emit(error);
                    return throwError(error);
                }))
    }
}
