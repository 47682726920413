import { createReducer, on } from '@ngrx/store';
import { EmployeeProfile } from '../../widgets/staar/models/employee-profile';
import { setEmployee } from './users.actions';

export type ReducerState = {
  employees: Record<string, EmployeeProfile>;
};

const initialState: ReducerState = {
  employees: {},
};

const _usersReducer = createReducer<ReducerState>(
  initialState,
  on(setEmployee, (state, { employee }) => {
    const employeeNumber = employee.EMPLOYEE_NUMBER;
    const employees = { ...state.employees };
    employees[employeeNumber] = employee;
    return { ...state, employees };
  })
);

export const usersReducer = (state, action) => _usersReducer(state, action);