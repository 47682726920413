/// <reference types="new-relic-browser" />
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class MonitoringService {
  constructor() {
    this.monkeyPatchConsoleError();
    this.catchUnhandledRejections();
  }

  // Monkey-patch console.error to send the errors to newrelic.
  // Long-term, it would be good to have a logging module and a
  // linter rule preventing direct use of the console, but this
  // works for now
  private monkeyPatchConsoleError() {
    if (!window.newrelic) {
      return;
    }
    const consoleError = console.error;
    const logErrorWithNewRelic = function (...args) {
      consoleError(...args);
      const msg = args.map(arg => arg.toString()).join(' ~ ')
      newrelic.noticeError(msg, { handled: 'true' });
    };
    console.error = logErrorWithNewRelic.bind(console);
  }

  // catch unhandled promise rejections and send to newrelic
  private catchUnhandledRejections() {
    if (!window.newrelic) {
      return;
    }
    window.addEventListener('unhandledrejection', (event) => {
      newrelic.noticeError(event.reason, { handled: 'false' });
    });
  }
}
