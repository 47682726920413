import { AfterViewInit, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ColorScheme } from './shared/models/color-schemes';
import { PersistedValueEnum } from './shared/models/persisted-value-enum';
import { UserClaim } from './shared/models/user-claim';
import { ColorSchemeService } from './shared/services/color-scheme.service';
import { ConcourseApiService } from './shared/services/concourse-api.service';
import { ConnectionService, ConnectionServiceOptions, ConnectionState } from 'ng-connection-service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { distinctUntilChanged, map, skip } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { ButtonConfiguration, ButtonFill, IonicColor, IonicSize, ToastConfiguration } from 'aar-ionic-component-library/dist/ngx-aar-ionic-library';
import { NetworkConnectivityState, NetworkOutageModel } from './shared/services/network-outage';
import { RouteGuardCommunicationService } from './shared/services/route-guard-communication.service';
import { Store } from '@ngrx/store';
import { ApplicationState } from './store/application-store';
import { MonitoringService } from './shared/services/monitoring.service';
import { RouteTransitionService } from './widgets/staar/services/route-transition.service';
import { AptyService } from './shared/services/apty.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  public dark = false;
  public networkOutageModel = new NetworkOutageModel();
  public readonly closeButtonConfig = new ButtonConfiguration(
    'Close',
    IonicColor.primary,
    IonicSize.small,
    ButtonFill.solid,
    undefined,
    undefined,
    false,
  );
  private readonly connectionServiceOptions: ConnectionServiceOptions = {
    enableHeartbeat: true,
    heartbeatUrl: `${environment.apimBaseUrl}${ConcourseApiService.pingUrl}`,
    heartbeatInterval: 15000,
    requestMethod: 'get',
  }
  private static colorSchemeService: ColorSchemeService;
  private static concourseApiService: ConcourseApiService;
  private networkSubscription = new Subscription();
  public displayToastSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public displayToastSubscription: Subscription;
  public toastNotification: ToastConfiguration = new ToastConfiguration(
    '',
    6000,
    'top',
    '',
    IonicColor.dangerSoft,
  );

  constructor(
    colorSchemeService: ColorSchemeService,
    public concourseApiService: ConcourseApiService,
    private connectionService: ConnectionService,
    public routeGuardCommunicationService: RouteGuardCommunicationService,
    private applicationStore: Store<ApplicationState>,
    private routeTransition: RouteTransitionService,
    private apty: AptyService,

    // monitoringService is not currently called directly, but by providing
    // it here its constructor runs early and catches errors earlier in the
    // startup process than a method call from ngOnInit would allow
    private monitoringService: MonitoringService,
  ) {
    AppComponent.colorSchemeService = colorSchemeService;
    AppComponent.concourseApiService = concourseApiService;
    this.routeGuardCommunicationService.displayAccessDeniedToast.subscribe((val) => {
      if (val) {
        this.displayAccessDeniedToastMessage('Access Denied', 'You do not have access to the requested route');
      }
    });
  }

  ngOnInit() {
    this.routeTransition.subscribe();
    this.apty.subscribe();
  }

  public ngAfterViewInit(): void {
    AppComponent.loadColorTheme();
    this.setupNetworkOutageNotification();
    this.subscribeToast();
    this.concourseApiService.getIPAddress().subscribe((res: any) => {
      if (res.ip) {
        localStorage.setItem('IP', res.ip);
      } else{
        console.error(res);
      }
    })
  }

  public ngOnDestroy(): void {
    this.networkSubscription?.unsubscribe();
    this.routeTransition.unsubscribe();
    this.apty.unsubscribe();
  }

  private setupNetworkOutageNotification(): void {
    this.networkSubscription = this.connectionService.monitor(this.connectionServiceOptions)
      .pipe(
        map((newState: ConnectionState): NetworkConnectivityState => {
          if (!newState.hasInternetAccess) {
            return NetworkConnectivityState.noInternet;
          } else if (!newState.hasNetworkConnection) {
            return NetworkConnectivityState.noConnection;
          } else {
            return NetworkConnectivityState.ok;
          }
        }),
        distinctUntilChanged(),
      ).subscribe((networkStatus: NetworkConnectivityState) => {
        this.networkOutageModel.networkStatus = networkStatus;
      });
  }

  public hideError(): void {
    this.networkOutageModel.dismissError();
  }

  public static loadColorTheme(): void {
    const userId = Number(localStorage.getItem(PersistedValueEnum.userId));
    this.colorSchemeService.load();
    const currentColorScheme = this.colorSchemeService.currentActive();
    this.colorSchemeService.update(currentColorScheme);
    if (!userId) {
      return;
    }
    this.concourseApiService
      .getUserClaimsByUserId(userId)
      .subscribe((res: UserClaim[]) => {
        const userClaims = res ? res.map((data) => {
          return {
            id: data.id,
            userId: data.userId,
            claimValue: JSON.parse(`${data.claimValue}`),
          };
        }) : [];
        this.loadInitialColorScheme(userClaims);
      });
  }

  private static loadInitialColorScheme(userClaims: UserClaim[]): void {
    const colorScheme = userClaims.find((claim) => {
      return claim.claimValue.hasOwnProperty('UITheme');
    });
    if (!colorScheme) {
      return;
    }
    const claimColor = colorScheme.claimValue['UITheme'];
    if (claimColor === ColorScheme.dark) {
      this.colorSchemeService.update(ColorScheme.dark);
    } else if (claimColor === ColorScheme.light) {
      this.colorSchemeService.update(ColorScheme.light);
    }
  }

  displayAccessDeniedToastMessage(header: string, message: string): void {
    this.toastNotification.header = header;
    this.toastNotification.message = message;
    this.displayToastSubject.next('');
  }


  // reads toast config and events from the application store, allowing
 // any subcomponent to trigger a toast event that displays here without
 // having to manage its own subscriptions and config.
 private subscribeToast() {
  this.applicationStore
    .select((state) => state.toast.toastConfig)
    .pipe(skip(1))
    .subscribe((toastConfig) => {
      for (const key in toastConfig) {
        if (key === 'buttons') continue; // don't clobber default dismiss btn
        this.toastNotification[key] = toastConfig[key];
      }
      setTimeout(() => this.displayToastSubject.next(''));
    });
  }
}
