export class NgeApiPaths {

    public static readonly subPath= 'imops/oem/catalogmanagement/partscatalog/v1/';
    public static readonly getToken= 'imops/authentication/v1/createToken';
    public static readonly getTokenV2= 'imops/authentication/v2/createToken';
    public static readonly fscList= '/imops/oem/catalogmanagement/partscatalog/v2/fscList';
    public static readonly partList= '/imops/oem/catalogmanagement/partscatalog/v2/partsList';
    public static readonly altPartList= NgeApiPaths.subPath + 'altPartsList';
    public static readonly priceBreakList= NgeApiPaths.subPath + 'pricebreakList';
    public static readonly oemModalList = 'imops/agreementmanagement/v1/OemList';
    public static readonly subPathCP= 'imops/catalogmanagement/customerpricing/v1/';
    public static readonly fscListCP = NgeApiPaths.subPathCP + 'fscList';
    public static readonly partListCP = 'imops/catalogmanagement/customerpricing/v2/partsList';
    public static readonly priceTiersList = NgeApiPaths.subPathCP + 'fsc/priceTiersList';
    public static readonly bulkUploadPath = "imops/oem/catalogmanagement/partscatalog/v1/bulkUpload";
    public static readonly plPricingManagerView = NgeApiPaths.subPathCP + 'productLine/partPrices';
    public static readonly partPricesByBatch = NgeApiPaths.subPathCP + 'productLine/partPricesByBatch';

    
    public static readonly customersListByTiers = NgeApiPaths.subPathCP + 'customersListByTiers';
    public static readonly quantityModifier = NgeApiPaths.subPathCP + 'fsc/quantityModifier';
    public static readonly editQuantityModifier = NgeApiPaths.subPathCP + 'fsc/editQuantityModifier';
    public static readonly fiscalYearModifier = NgeApiPaths.subPathCP + 'fsc/fiscalYearModifier';
    public static readonly editFiscalYearModifier = NgeApiPaths.subPathCP + 'fsc/addUpdateModifiers';
    public static readonly fiscalYearModifierPart = NgeApiPaths.subPathCP + 'parts/fiscalYearModifier';
    public static readonly quantityModifierFsc = NgeApiPaths.subPathCP + 'fsc/quantityModifier';
    public static readonly editQuantityModifierFsc = NgeApiPaths.subPathCP + 'fsc/addUpdateModifiers';
    public static readonly viewTiersFsc = NgeApiPaths.subPathCP + 'fsc/PricingTiers';
    public static readonly editTiersFsc = NgeApiPaths.subPathCP + 'fsc/addUpdateTiers';    
    public static readonly viewTiersParts = NgeApiPaths.subPathCP + 'parts/priceTiers';
    public static readonly editTiersParts = NgeApiPaths.subPathCP + 'parts/addUpdateTiers';
    public static readonly tiersListParts = '/imops/catalogmanagement/customerpricing/v1/parts/tiersList';
    public static readonly deleteTiersFsc =  NgeApiPaths.subPathCP + 'fsc/deleteTiers';
    public static readonly deleteTiersParts =  NgeApiPaths.subPathCP + 'parts/deleteTiers';                    
    public static readonly quantityModifierParts = NgeApiPaths.subPathCP + 'parts/quantityModifier';
    public static readonly editQuantityModifierParts = NgeApiPaths.subPathCP + 'parts/addUpdateModifiers';
    public static readonly searchByPartNumber = '/imops/oem/catalogmanagement/partscatalog/v1/searchByPartNoDescription'
    public static readonly editFiscalYearModifierForPart = NgeApiPaths.subPathCP + 'parts/addUpdateModifiers';
    public static readonly fscTiersList = 'imops/catalogmanagement/customerpricing/v1/fsc/tiersList';
    public static readonly pseffIdListFsc = NgeApiPaths.subPathCP + 'fsc/pseffIdList';
    public static readonly pseffIdListPart = NgeApiPaths.subPathCP + 'parts/pseffIdList';
    public static readonly deleteFSCQuantityModifier  = NgeApiPaths.subPathCP + 'fsc/deleteQuantityModifier';
    public static readonly deletePartQuantityModifier  = NgeApiPaths.subPathCP + 'parts/deleteQuantityModifier';
    public static readonly textPricing  = '/imops/catalogmanagement/customerpricing/v1/sales/pricing';
    public static readonly verifyPricing = '/imops/catalogmanagement/customerpricing/v1/verify/pricing';
    public static readonly getOemsByDivision = NgeApiPaths.bulkUploadPath + "/configurationByOemName";
    public static readonly getAvailableFscs = NgeApiPaths.bulkUploadPath + "/configurationByFscNumber";
    public static readonly getAvailablDivisions = NgeApiPaths.bulkUploadPath + "/configurationByDivision";
    public static readonly getListedDivision = NgeApiPaths.subPath + "listeddivisions";    
    public static readonly getAvailablDivisionsList ='/imops/customer/v1/divisionList';
    public static readonly getAvailablVendorList = NgeApiPaths.subPath + 'vendorList';
    public static readonly postExportPricingList = NgeApiPaths.subPath + 'pricingExport/downloadFileToImops';

    public static readonly postBulkUploadToImops = NgeApiPaths.bulkUploadPath + '/importFileToImops';
    public static readonly getBulkUploadLog = NgeApiPaths.bulkUploadPath + '/downloadLog';
    public static readonly getBulkUploadSuccessParts = NgeApiPaths.bulkUploadPath + '/successRecordsCount';
    public static readonly getBulkUploadErrorPartsCount = NgeApiPaths.bulkUploadPath + '/errorRecordsCount';
    public static readonly getBulkUploadErrorCategoryRecords = NgeApiPaths.bulkUploadPath + '/errorCategoryRecordsCount';
    public static readonly getBulkUploadPartsList = NgeApiPaths.bulkUploadPath + '/partsList';
    public static readonly getBulkUploadTotalRecords = NgeApiPaths.bulkUploadPath + '/totalRecordsCount';
    public static readonly getCostPriceBreaks = NgeApiPaths.subPath + 'part/costpricebreaks';
    public static readonly costDataCud = NgeApiPaths.subPath + 'cud/pricebreaks';
    public static readonly getTierNamesList = NgeApiPaths.subPathCP + 'pricingstructure/tiersNamesList';
    public static readonly getAllCustomers = NgeApiPaths.subPathCP + 'allCustomers';
    public static readonly editFiscalYearModifierInPart =   NgeApiPaths.subPathCP + 'fsc/editFiscalYearModifier';
    public static readonly customerPricingTiersByCustId = NgeApiPaths.subPathCP + 'price/customerPricingTiersByCustId';
    public static readonly customerPricingTiersList = NgeApiPaths.subPathCP + 'price/customerPricingTiersList';
    public static readonly assignCustomersToTier = NgeApiPaths.subPathCP + 'price/assignCustomersToTier';
    public static readonly fscsAssignedByTiers = NgeApiPaths.subPathCP + 'fsc/fscByTiers';
    public static readonly partsByTiers = NgeApiPaths.subPathCP + 'parts/partsByTiers';

    public static readonly retreiveAllOemParts = NgeApiPaths.subPathCP + '/retreiveAllOemParts';
    public static readonly getOemParentOrganizations = NgeApiPaths.subPath + '/parentOrganization';
    public static readonly getProductLineManagers = NgeApiPaths.subPath + '/productLineManager';
    public static readonly addUpdateOem = NgeApiPaths.subPath + '/addUpdateOem';
} 